import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, MenuItem, InputLabel, Select, FormControl, IconButton, 
  TextField, Card, CardContent, Snackbar, Alert, Divider, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de';
import { isBefore, startOfDay } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import RecurringEventControls from './RecurringEventControls'; 
import ACMapping from './ACMapping';

const EditEvaluationTemplate = ({ userProjects, editData, handleEditChange, isViewAction, handleLocalSaveClick, onBackClick, action}) => {
  const [localEditData, setLocalEditData] = useState({ ...editData });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [dateError, setDateError] = useState('');

  useEffect(() => {
    setLocalEditData({ ...editData });
    validateForm(editData);
  }, [editData]);

  const validateDates = (startDate, endDate) => {
    if (!startDate || !endDate) return true; // Let the required field validation handle this
    
    const start = startOfDay(new Date(startDate));
    const end = startOfDay(new Date(endDate));
    
    return !isBefore(end, start);
  };

  const validateForm = (data) => {
    // First validate dates
    const areDatesValid = validateDates(data.start_date, data.end_date);
    setDateError(areDatesValid ? '' : 'Das Enddatum muss nach dem Startdatum liegen');

    const isValid = Boolean(
      data.name &&
      data.description &&
      data.start_date &&
      data.end_date &&
      data.user_prompt &&
      data.project &&
      data.paths &&
      Array.isArray(data.paths) &&
      data.paths.length > 0 &&
      areDatesValid
    );
    setIsFormValid(isValid);
    return isValid;
  };

  useEffect(() => {
    validateForm(localEditData);
  }, [localEditData]);

  const validateAllFields = () => {
    if (!validateForm(localEditData)) {
      let message = 'Bitte füllen Sie alle erforderlichen Felder aus.';
      if (!localEditData.paths || !Array.isArray(localEditData.paths) || localEditData.paths.length === 0) {
        message = 'Bitte wählen Sie mindestens einen Pfad aus.';
      } else if (!validateDates(localEditData.start_date, localEditData.end_date)) {
        message = 'Das Enddatum muss nach dem Startdatum liegen';
      }
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      return false;
    }
    return true;
  };

  const onSave = () => {
    if (validateAllFields()) {
      handleLocalSaveClick(localEditData);
    }
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const [selectedProject, setSelectedProject] = useState({
    fullPath: '',
    projectName: editData.project ? editData.project.split('/').pop() : ''
  });

  const handleReportDataChange = (newData) => {
    const updatedData = { ...localEditData, paths: newData };
    setLocalEditData(updatedData);
    handleEditChange('paths', newData);
  };

  const VerticalDivider = styled(Divider)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }));

  const handleProjectSelection = (event) => {
    const fullPath = event.target.value;
    const projectName = fullPath.split('/').pop();
    
    setSelectedProject({ fullPath, projectName });
    const updatedData = { ...localEditData, project: projectName };
    setLocalEditData(updatedData);
    handleEditChange('project', projectName);
  };

  const handleFieldChange = (field, value) => {
    const updatedData = { ...localEditData, [field]: value };
    setLocalEditData(updatedData);
    handleEditChange(field, value);
  };

  const isEditAction = () => action === 'edit';

  return (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={2} direction="column">

      
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="h6" align="left">Planung</Typography>

                  <FormControl fullWidth margin="normal">
                    <InputLabel>Projekt</InputLabel>
                    <Select
                      labelId="project-label"
                      id="project"
                      required
                      value={selectedProject.projectName}
                      onChange={handleProjectSelection}
                      label="Projekt"
                      disabled={isEditAction()}
                      sx={{ backgroundColor: 'white' }}
                    >
                      {userProjects.map((projectItem) => (
                        <MenuItem key={projectItem} value={projectItem.split('/').pop()}>
                          {projectItem.split('/').pop()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  
                  <TextField
                    label="Titel"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                    disabled={isEditAction()}
                    value={localEditData.name || ''}
                    onChange={(e) => handleFieldChange('name', e.target.value)}
                  />
                  <TextField
                    label="Beschreibung"
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    value={localEditData.description || ''}
                    onChange={(e) => handleFieldChange('description', e.target.value)}
                  />

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, marginBottom: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                      <DatePicker
                        label="Startdatum"
                        required
                        value={localEditData.start_date}
                        onChange={(newValue) => handleFieldChange('start_date', newValue)}
                        slotProps={{
                          textField: {
                            error: Boolean(dateError),
                            helperText: dateError
                          }
                        }}
                      />
                      <DatePicker
                        label="Enddatum"
                        required
                        value={localEditData.end_date}
                        onChange={(newValue) => handleFieldChange('end_date', newValue)}
                        minDate={localEditData.start_date}
                        slotProps={{
                          textField: {
                            error: Boolean(dateError),
                            helperText: dateError
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <RecurringEventControls />
                </Grid>

                <VerticalDivider orientation="vertical" flexItem />

                <Grid item xs>            
                  <Grid item xs>
                    <Card>
                      <CardContent>
                        <ACMapping 
                          onDataChanged={handleReportDataChange} 
                          document={localEditData.paths} 
                          selectedProject={selectedProject.projectName}
                        />

                        <Box sx={{marginTop:2}}>
                          <Typography variant="h6" align="left" gutterBottom>
                            Anweisungen
                          </Typography>

                          <Typography variant="subtitle1" align="left" gutterBottom>
                            Zusätzliche Hinweise für die Bewertung.
                          </Typography>

                          <TextField
                            label="Anweisungen"
                            variant="outlined"
                            required
                            fullWidth
                            margin="normal"
                            multiline
                            rows={13}
                            value={localEditData.user_prompt || ''}
                            onChange={(e) => handleFieldChange('user_prompt', e.target.value)}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button 
            variant="contained" 
            color="primary" 
            disabled={isViewAction || !isFormValid}
            onClick={onSave}
            style={{ margin: '8px' }}
          >
            Speichern
          </Button>
          <IconButton onClick={onBackClick}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ClickAwayListenerProps={{ 
          mouseEvent: false,
          touchEvent: false 
        }}
      >
        <Alert 
          onClose={closeSnackbar} 
          severity="warning" 
          variant="filled"
          sx={{ width: '100%' }}
          elevation={6}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditEvaluationTemplate;