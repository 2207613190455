// ProjectComparisonMatrix.jsx
import React, { useState, useEffect } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Button, CircularProgress, Typography, Tooltip, Box, IconButton, TextField } from '@mui/material';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import BarChartIcon from '@mui/icons-material/BarChart';
import config from '../config';
import '../styles/ProjectComparisonMatrix.css';
import ProjectComparisonDrilldownProjects from './ProjectComparisonDrilldownProjects';
import ProjectComparisonDrilldownCriteria from './ProjectComparisonDrilldownCriteria';

const ProjectComparisonMatrix = ({ selectedProjects, selectedReports, onClose }) => {
  const [criteriaScores, setCriteriaScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [projectsDrilldownData, setProjectsDrilldownData] = useState(null);
  const [criteriaDrilldownData, setCriteriaDrilldownData] = useState(null); 
  const [projectPrices, setProjectPrices] = useState({});

  // Step 1: Fetch Reports and Calculate Criteria Scores
  useEffect(() => {
        // Extract unique acceptance criteria
        const uniqueCriteria = new Set();
        const projectScores = {};

        selectedReports.forEach((report) => {
          const projectId = report.project;
          // Skip if the project is not in the selectedProjects array
          if (!selectedProjects.includes(projectId)) {
              return;
          }

          const { document } = report;

          if (!projectScores[projectId]) {
            projectScores[projectId] = {};
          }

          Object.values(document).forEach((doc) => {
            doc.acceptance_criterias.forEach((criteria) => {
              uniqueCriteria.add(criteria.name);

              const validScores = criteria.requirements
                .map((req) => req.score)
                .filter((score) => score !== -1);
              
              const sumOfScores = validScores.reduce((acc, score) => acc + score, 0);
              const countOfScores = validScores.length;
              
              if (!projectScores[projectId][criteria.name]) {
                // Initialize structure to track cumulative data
                projectScores[projectId][criteria.name] = {
                    totalScore: 0, // Cumulative score across documents
                    totalCount: 0, // Total number of scores across documents
                    documents: [], // List of contributing documents
                };
              }
      
              // Update cumulative score and count
              projectScores[projectId][criteria.name].totalScore += sumOfScores;
              projectScores[projectId][criteria.name].totalCount += countOfScores;
      
              // Add the current report to the contributing documents
              projectScores[projectId][criteria.name].documents.push({
                  reportName: report.name,
                  reportDate: report.creation_date,
                  documentName: doc.name,
                  scores: validScores,
              });
            });
          });

          // After processing all documents, calculate the average score for each criterion
          Object.keys(projectScores).forEach((projectId) => {
            Object.keys(projectScores[projectId]).forEach((criterion) => {
                const data = projectScores[projectId][criterion];
                data.avgScore = data.totalCount > 0 ? data.totalScore / data.totalCount : 0;
            });
          });
        });

        // Convert data for the DataGrid rows
        const rows = selectedProjects.map((project) => {
            const row = { id: project, project };
    
            Object.entries(projectScores[project] || {}).forEach(([criterion, value]) => {
                row[criterion] = value.avgScore; // Add the average score
                row[`${criterion}_documents`] = value.documents; // Add contributing documents
            });
    
            return row;
        });

        setCriteriaScores({ columns: Array.from(uniqueCriteria), rows });
        setLoading(false);

        // Initialize project prices with default 1 million CHF
        const defaultPrices = Object.fromEntries(
          selectedProjects.map((project) => [project, 1000000])
        );
        setProjectPrices(defaultPrices);

  }, [selectedProjects, selectedReports]);

  // Ensure criteriaScores and columns are defined
  const columnsData = criteriaScores?.columns || [];
  const actualRowCount = criteriaScores.rows?.length || 0;
  // Filter columns to include only those with at least one non-null value
  const nonEmptyColumns = columnsData.filter((criterion) =>
    criteriaScores?.rows?.some((row) => row[criterion] !== null && row[criterion] !== undefined)
  );
  // Minimum width for the project column to ensure readability
  const projectColumnWidth = 250; 
  // Calculate the remaining width after assigning the project column
  const remainingWidth = window.innerWidth - projectColumnWidth - config.ui_settings.drawer_width;
  // Get the dynamic width for each criterion column based on the number of columns
  const numCriteriaColumns = criteriaScores.columns?.length || 1;
  const criteriaColumnWidth = remainingWidth / numCriteriaColumns;

  // Helper function to interpolate colors between the min and max values
  const getScoreClass = (value) => {
    if (value === null || value === undefined) return '';
    if (value < 0.4) return 'range-low';
    if (value < 0.8) return 'range-low-medium';
    if (value < 1.2) return 'range-medium';
    if (value < 1.6) return 'range-medium-high';
    return 'range-high';
  };

  const openCriteriaDrilldown = (selected_project) => {
    const projectData = criteriaScores.rows.find((row) => row.project === selected_project);
    const filteredData = Object.fromEntries(
      Object.entries(projectData).filter(([key]) => !key.includes('_documents') && key!=='id' && key!=='project')
    );
    //console.log('Filtered drilldown data:', filteredData);
    setCriteriaDrilldownData(filteredData);
  };

  const openProjectsDrilldown = (criterion) => {
    const data = selectedProjects
        .map((project) => {
            const row = criteriaScores.rows.find((row) => row.project === project);
            const documents = row?.[`${criterion}_documents`] || [];

            // Group documents by report name and date
            const reports = documents.reduce((acc, doc) => {
                const key = `${doc.reportName}_${doc.reportDate}`;
                if (!acc[key]) {
                    acc[key] = {
                        reportName: doc.reportName,
                        reportDate: doc.reportDate,
                        scores: [],
                    };
                }
                acc[key].scores = acc[key].scores.concat(doc.scores);
                return acc;
            }, {});

            // Calculate averages for each report
            const reportAverages = Object.values(reports).map((report) => ({
                reportName: report.reportName,
                reportDate: report.reportDate,
                avgScore: report.scores.reduce((sum, score) => sum + score, 0) / report.scores.length,
            }));

            return {
                project,
                reports: reportAverages,
            };
        })
        .filter((projectData) => projectData.reports.length > 0); // Remove projects with no reports

    // Debugging output
    console.log('Filtered drilldown data:', data);

    setProjectsDrilldownData({ criterion, data });
};

  const closeCriteriaDrilldown = () => {
    setCriteriaDrilldownData(null);
  };

  const closeProjectsDrilldown = () => {
    setProjectsDrilldownData(null);
  };

  // Update the rows to include sum and calculated value columns
  const processedRows = criteriaScores.rows?.map((row) => {
    // Calculate sum of criteria scores
    const criteriaColumns = criteriaScores.columns || [];
    const sumOfCriteria = criteriaColumns.reduce((sum, criterion) => {
      const score = row[criterion] || 0;
      return sum + score;
    }, 0);

    // Calculate value (price / sum)
    const projectPrice = projectPrices[row.project] || 1000000;
    const calculatedValue = sumOfCriteria > 0 
      ? (projectPrice * sumOfCriteria).toFixed(2)
      : null;

    return {
      ...row,
      sum: sumOfCriteria.toFixed(2),
      calculatedValue,
    };
  }) || [];

  // Handle price change for a specific project
  const handlePriceChange = (project, newPrice) => {
    setProjectPrices(prev => ({
      ...prev,
      [project]: parseFloat(newPrice) || 1000000
    }));
  };

  // Utility function for Swiss number formatting
  const formatSwissNumber = (value) => {
    if (value == null) return '-';
    
    // Split integer and decimal parts
    const [integerPart, decimalPart] = value.toString().split('.');
    
    // Add thousand separators
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    
    // Combine with decimal part if exists
    return decimalPart 
      ? `${formattedInteger}.${decimalPart}` 
      : formattedInteger;
  };

  // Step 2: Render Matrix with DataGrid Pro
  const columns = [
    {
      field: 'project',
      headerName: 'Projekte',
      width: projectColumnWidth,
      renderCell: (params) => {
        const value = params.value;

        return (
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{margin:1}}>
            <IconButton 
            size="small" 
            color="primary" 
            onClick={() => openCriteriaDrilldown((value))}
            sx={{
              width: 30,
              height: 30, 
              margin: 0.75,
              border: '2px solid',
              borderColor: 'primary.main',
              borderRadius: '50%',
              cursor: 'pointer'
            }}>
              <BarChartIcon fontSize="inherit" />
            </IconButton>
            <Typography>{value}</Typography>
            </Box>
        )
      }
    },
    {
      field: 'price',
      headerName: 'Preis (CHF)',
      flex:0.15, 
      editable: true,
      renderCell: (params) => {
        const project = params.row.project;
        const currentPrice = projectPrices[project];
        
        return (
          <TextField
            value={currentPrice.toLocaleString('de-CH', {
              useGrouping: true,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
            type="text"
            onChange={(e) => {
              // Remove any non-digit characters
              const cleanedValue = e.target.value.replace(/[^\d]/g, '');
              handlePriceChange(project, parseFloat(cleanedValue) || 1000000);
            }}
            variant="standard"
            InputProps={{
              inputProps: { 
                min: 0,
                step: 1000 
              }
            }}
          />
        );
      }
    },
    ...(nonEmptyColumns.map((criterion) => ({
      field: criterion,
      headerName: (
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{margin:1}}>
          <IconButton 
            size="small" 
            color="primary" 
            onClick={() => openProjectsDrilldown(criterion)}
            sx={{
              /* width: 30,
              height: 30,  */
              margin: 0.75,
              border: '2px solid',
              borderColor: 'primary.main',
              borderRadius: '50%',
              cursor: 'pointer'
            }}>
            <AutoGraphIcon fontSize="inherit" />
          </IconButton>
          <span>{criterion}</span>
        </Box>
      ),
      flex:0.30, 
      align: 'center',
      renderCell: (params) => {
        const value = params.value;
        const contributingDocuments = params.row[`${params.field}_documents`] || []; // Get the reports arra

        return (
          <Tooltip
            title={
              contributingDocuments.length > 0 ? (
                <div>
                  <Typography variant="subtitle2">Beiträge von {contributingDocuments.length} Dokumenten</Typography>
                </div>
              ) : (
                'Keine Berichte, die dazu beitragen'
              )
            }
          >
            <Typography
              style={{
                padding: '10px',
                borderRadius: '4px'
              }}
            >
              {value !== null && value !== undefined ? value.toFixed(2) : '-'}
            </Typography>
          </Tooltip>
        )},
    }))),
    {
      field: 'sum',
      headerName: 'Summe',
      flex:0.1, 
      renderCell: (params) => (
        <Typography>{params.value || '-'}</Typography>
      )
    },
    {
      field: 'calculatedValue',
      headerName: 'Preis * Summme',
      flex:0.15, 
      renderCell: (params) => (
        <Typography>{formatSwissNumber(params.value) || '-'}</Typography>
      )
    }
  ];

  return (
    <div style={{ width: '100%', overflow: 'visible' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 16 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose} 
        >
          Zurück
        </Button>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div style={{display: 'flex', flexGrow: 1}}>
          <DataGridPro
            //rows={criteriaScores.rows}
            rows={processedRows}
            columns={columns}
            slots={{ 
              toolbar: GridToolbar,
              footer: () => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '8px 16px',
                        borderTop: '1px solid #e0e0e0',
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    <Typography variant="body2" color="textSecondary">
                        Legende: Min (0) - Max (2)
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Total: {actualRowCount}
                    </Typography>
                    
                </Box>
            ),
            
            }}
            autoHeight
            pageSizeOptions={[5, 10, 20]}
            getCellClassName={(params) => {
              if (params.value == null || params.field === 'project' || params.field === 'price' || params.field === 'calculatedValue' || params.field === 'sum') return '';
              return getScoreClass(params.value); // Apply score-based class
            }}
            sx={{
              '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f5f5f5' },
            }}
          />
        </div>
      )}
      {projectsDrilldownData && (
        <ProjectComparisonDrilldownProjects
          criterion={projectsDrilldownData.criterion}
          data={projectsDrilldownData.data}
          onClose={closeProjectsDrilldown}
        />
      )}
      {criteriaDrilldownData && (
        <ProjectComparisonDrilldownCriteria
          data={criteriaDrilldownData}
          onClose={closeCriteriaDrilldown}
        />
      )}
    </div>
  );
};

export default ProjectComparisonMatrix;
