import React from "react";
import { Button, Typography, Box } from "@mui/material";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

function AssistantNavigation({
    isProcessing, 
    currentStep,
    handlePrevStep,
    handleExecute,
    isFormValid,
    executionStep,
    handleEditToggle,
    isEditing,
    handleClose
}) {

    return(
        <Box 
            sx={{ 
                position: 'relative', // Ensure buttons stay within the parent container
                mt: 'auto', // Push to the bottom
                p: 2,
                bgcolor: 'background.default'
            }}
        >
            {currentStep >= 0 && (
                <Button
                    onClick={handlePrevStep}
                    variant="outlined"
                    disabled={isProcessing}
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        margin: 2
                    }}
                >
                    Zurück
                </Button>
            )}
            {currentStep === 0 && (
                <>
                    <Button
                        onClick={handleExecute}
                        variant="contained"
                        disabled={!isFormValid() || isProcessing} 
                        startIcon={<PlayCircleOutlineIcon />}
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            margin: 2
                        }}
                    >
                        Ausführen
                    </Button>
                    <Typography variant="body2" color="textSecondary" sx={{ position: 'absolute', bottom: 0, right: 200, margin: 2 }}>
                        {executionStep}
                    </Typography>
                </>
            )}
            {currentStep === 1 && (
                <>
                    <Button
                        onClick={handleEditToggle}
                        variant="contained"
                        disabled={isProcessing}
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 100, // Adjust button position
                            margin: 2
                        }}
                    >
                        {isEditing ? 'Änderungen speichern' : 'Bearbeiten'}
                    </Button>

                    <Button
                        onClick={() => {/* Logic to generate PDF/CSV */}}
                        variant="outlined"
                        disabled={isEditing}
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 190, // Adjust button position
                            margin: 2
                        }}
                    >
                        Generate PDF/CSV
                    </Button>

                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            margin: 2
                        }}
                    >
                        Assistent Beenden
                    </Button>
                </>
            )}
        </Box>
    )
}

export default AssistantNavigation;