import React, { useState, useEffect } from 'react';
import { Modal, LinearProgress, Box, Typography, AppBar, Toolbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AssistantIcon from '@mui/icons-material/Assistant';
import { useAppContext } from '../contexts/AppContext';
import { useAuth } from '../contexts/AuthContext';
import config from '../config';
import documentData from '../intermediate_document_data.json';
import extractionSchemas from '../data_extraction_schemas.json';
import enrichedCategories from '../enriched_categories.json';
import '../styles/Assistant.css'
import AssistantMain from './AssistantMain';
import AssistantConfiguration from './AssistantConfiguration';
import AssistantOutput from './AssistantOutput';
import AssistantNavigation from './AssistentNavigation';

function Assistant() {
    const [open, setOpen] = useState(false);
    const [currentProcess, setCurrentProcess] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({ additionalInfo: [] }); // Store additional information provided by user
    const [selectedProject, setSelectedProject] = useState('');
    const [activatedContexts, setActivatedContexts] = useState([]);
    const [acceptanceCriteria, setAcceptanceCriteria] = useState([]);
    const [selectedCriteria, setSelectedCriteria] = useState([]);
    const [modelResponse, setModelResponse] = useState('');
    const [questionAnswers, setQuestionAnswers] = useState({});
    const [isEditing, setIsEditing] = useState(false); // Track if the user is in editing mode
    const [editableResponse, setEditableResponse] = useState('');
    const [isProcessing, setIsProcessing] = useState(false); // Track if processing is happening
    const [executionStep, setExecutionStep] = useState(''); // Track background steps during execution
    const [animationClass, setAnimationClass] = useState('');
    const [phases, setPhases] = useState([]);
    const [fachbereiche, setFachbereiche] = useState([]);
    const [selectedPhase, setSelectedPhase] = useState('');
    const [selectedFachbereiche, setSelectedFachbereiche] = useState([]);
    const [folderData, setFolderData] = useState(null);
    const [parseResults, setParseResults] = useState(null);
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const { getAcceptanceCriteria, queryGenerativeModel, getSearchResultsInDocsStatelessV2, fetchReportResults, getProject, getExtractedData } = useAppContext();
    const { currentTenant } = useAuth();
    const max_elements = 5;
    const max_depth = 3;
    let provider = null;
    let model = null;
    let response_format = null;
    const isProjektdokumentation = currentProcess?.name === "Projektdokumentation";
    const isDataExtraction = currentProcess?.name === "Datenextraktion";
    const isSNBS = currentProcess?.name === "Vorprüfung SNBS";

    // Fetch projects and acceptance criteria on component load
    useEffect(() => {
        if (open) {
            getAcceptanceCriteria().then((criteria) => setAcceptanceCriteria(criteria));
        }
    }, [open]);

     // On page reload, trigger background color change a few times
    useEffect(() => {
        // Start the animation by adding the class
        setAnimationClass('pulse-animation');

        // Remove the animation class after a few seconds
        const timeout = setTimeout(() => {
            setAnimationClass('');
        }, 3000); // Animation duration is 3 seconds, adjust as necessary

        // Cleanup the timeout on component unmount
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (isProjektdokumentation) {
            const uniquePhases = [...new Set(documentData.flatMap(doc => doc.sia_phasen))];
            const uniqueFachbereiche = [...new Set(documentData.flatMap(doc => doc.fachbereiche))];
            setPhases(uniquePhases);
            setFachbereiche(uniqueFachbereiche);
        }
    }, [currentProcess]);
    
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setCurrentProcess(null);
        setCurrentStep(0);
        setFormData({ additionalInfo: [] });
        setSelectedCriteria([]);
        setActivatedContexts([]);
        setModelResponse('');
        setQuestionAnswers({});
        setFachbereiche([]);
        setSelectedPhase('');
        setSelectedFachbereiche([]);
        setSelectedIndicators([]);
        setParseResults(null);
        setFolderData(null);
    };

    const handlePrevStep = () => {
        setCurrentStep((prevStep) => {
            // If on the first step, reset the process name by setting currentProcess to null
            if (prevStep === 0) {
                setCurrentProcess(null);
                setCurrentStep(0);
                setFormData({ additionalInfo: [] });
                setSelectedCriteria([]);
                setActivatedContexts([]);
                setModelResponse('');
                setQuestionAnswers({});
                setFachbereiche([]);
                setSelectedPhase('');
                setSelectedFachbereiche([]);
                setSelectedIndicators([]);
                setParseResults(null);
                setFolderData(null);
            }
            return prevStep - 1;
        });
    };
    
    const handleProcessSelect = (processName) => {
        const selectedProcess = config.assistant.processes.find(process => process.name === processName);
        if (!selectedProcess.questions) {
            selectedProcess.questions = []; // Initialize questions as an empty array if undefined
        }
        setCurrentProcess(selectedProcess);
        setCurrentStep(0);
        setActivatedContexts(selectedProcess.contexts || []); // Ensure contexts is always an array
    };

    const handleEditToggle = () => {
        if (isEditing) {
            // Save the changes and update the model response
            setModelResponse(editableResponse);
            setIsEditing(false);
        } else {
            // Enter editing mode and populate the editable field
            setEditableResponse(modelResponse);
            setIsEditing(true);
        }
    };

    const isFormValid = () => {
        // Check if all required fields are filled
        const isProjectSelected = !!selectedProject;
        const areQuestionsAnswered = currentProcess?.questions.every((_, index) => questionAnswers[index]);
        const isAdditionalInfoFilled = formData.additionalInfo.every(info => info.trim() !== '');
        const isAtLeastOneContextSelected = activatedContexts.length > 0;
        const areEnoughFachbereicheSelected = selectedFachbereiche.length >= 1;
        const isPhaseSelected = selectedPhase !== '';
        const areIndicatorsSelected = selectedIndicators.length > 0;
        const existParseData = parseResults !== null;
    
        if (isProjektdokumentation) {
            return isProjectSelected && areQuestionsAnswered && isAdditionalInfoFilled && isAtLeastOneContextSelected && isPhaseSelected && areEnoughFachbereicheSelected;
        } else if (isDataExtraction) {
            return existParseData;
        } else if (isSNBS) {
            return isProjectSelected && areIndicatorsSelected;
        } else {
            return isProjectSelected && areQuestionsAnswered && isAdditionalInfoFilled && isAtLeastOneContextSelected;
        }
    };

    const context_dir = {
        'strategie': { path: `/${currentTenant}/${selectedProject}/Strategie`, label: 'Strategie' },
        'protokolle': { path: `/${currentTenant}/${selectedProject}/Protokolle`, label: 'Protokolle' },
        'vorgaben': { path: `/${currentTenant}/Vorgaben Vorlagen und Richtlinien`, label: 'Vorgaben' },
        'kbob': { path: `/${currentTenant}/Vorgaben Vorlagen und Richtlinien/Projektdokumentation`, label: 'KBOB' },
        'berichte': { path: ``, label: 'Berichte' },
        'snbs': { path: `/${currentTenant}/${selectedProject}`, label: 'SNBS' },
        'projektdetails': { path: ``, label: 'Projektdetails' },
        'aufgaben': { path: ``, label: 'Aufgaben' },
        'uploaded_documents': { path: ``, label: 'Hochgeladene Dokumente'}
    };

    function extractAndGroupByIndicatorWithVerbund(data) {
        const groupedIndicators = {};
    
        data.forEach(indicator => {
            if (!groupedIndicators[indicator.kriteriumIndikatorNr]) {
                groupedIndicators[indicator.kriteriumIndikatorNr] = {
                    indicator: indicator.kriteriumIndikatorNr + " " + indicator.titel,
                    verbundData: {},
                };
            }
    
            indicator.messgroesseVerbunde.forEach(verbund => {
                if (!groupedIndicators[indicator.kriteriumIndikatorNr].verbundData[verbund.titel]) {
                    groupedIndicators[indicator.kriteriumIndikatorNr].verbundData[verbund.titel] = [];
                }
    
                verbund.messgroessen.forEach(messgroesse => {
                    groupedIndicators[indicator.kriteriumIndikatorNr].verbundData[verbund.titel].push({
                        allgemeinerText: messgroesse.allgemeinerText,
                        vorpruefung: messgroesse.vorpruefung,
                        nachweisKp1: messgroesse.nachweisKp1,
                        nachweisKp2: messgroesse.nachweisKp2,
                        messgroesseTitel: messgroesse.titel,
                    });
                });
            });
        });
    
        // Format the final result
        return Object.values(groupedIndicators).map(group => ({
            indicator: group.indicator,
            verbundData: Object.entries(group.verbundData).map(([verbundTitel, messgroessen]) => ({
                verbundTitel,
                messgroessen,
            })),
        }));
    };


    async function fetchPhaseRequirements(contextPath, phase, requirementsList, threshold) {
        const results = [];
    
        for (const requirement of requirementsList) {
            try {
                const query = cleanHtml(requirement.requirements[phase]);
                if (query) {
                    const result = await getSearchResultsInDocsStatelessV2(
                        config.envs[config.active_env].domain + config.api_path,
                        3,//max_elements,
                        1,//max_depth,
                        contextPath,
                        query
                    );
                    results.push({
                        phase,
                        query,
                        result,
                    });
                } else {
                    console.warn(`Phase "${phase}" not found for requirement`, requirement);
                }
            } catch (error) {
                console.error(`Error fetching results for phase "${phase}" with query "${requirement.requirements[phase]}":`, error);
                results.push({
                    phase,
                    query: requirement.requirements[phase],
                    error: error.message,
                });
            }
        }
    
        return results;
    };

    function cleanHtml(htmlContent) {
        if (!htmlContent) return "";
    
        // Create a temporary DOM element to parse the HTML
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlContent;
    
        // Extract text content from list items and join them
        const listItems = Array.from(tempDiv.querySelectorAll("li"))
            .map((li) => li.textContent.trim())
            .join(", ");
    
        // Extract remaining text from the document excluding list items
        const otherText = tempDiv.textContent
            .replace(/(\n|\r|\t)+/g, " ") // Remove line breaks
            .trim();
    
        // Combine list items with other text, prioritizing structured content
        return listItems ? listItems : otherText;
    };

    const generateRequirementReport = (data = []) => {
        if (!Array.isArray(data) || data.length === 0) {
            return "No data available";
        }
    
        return data
            .map((entry) => {
                const indicatorTitle = entry.indicator;
    
                const verbundSections = entry.verbundData
                    .map((verbund) => {
                        const verbundTitle = verbund.verbundTitel;
    
                        const messgroessenDetails = verbund.messgroessen
                            .map((messgroesse) => {
                                return `
#### Messgrösse: ${messgroesse.messgroesseTitel}
- Allgemeiner Text: ${messgroesse.allgemeinerText || "Nicht verfügbar"}
- Vorprüfung: ${messgroesse.vorpruefung || "Nicht verfügbar"}
- Nachweis KP1: ${cleanHtml(messgroesse.nachweisKp1 || "Nicht verfügbar")}
- Nachweis KP2: ${cleanHtml(messgroesse.nachweisKp2 || "Nicht verfügbar")}
                                `;
                            })
                            .join("\n");
    
                        return `
### Verbund: ${verbundTitle}
${messgroessenDetails}
                        `;
                    })
                    .join("\n");
    
                return `
## Indikator: ${indicatorTitle}
${verbundSections}
                `;
            })
            .join("\n\n");
    };

    const generatePrompts = (jsonSchema) => {
        // Function to recursively extract keys and format them hierarchically
        const extractKeys = (obj, prefix = "") => {
          let keys = [];
          for (let key in obj) {
            if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
              // Nested object
              keys.push(`${prefix}${key}:`);
              keys = keys.concat(extractKeys(obj[key], "  " + prefix));
            } else if (Array.isArray(obj[key]) && obj[key].length > 0 && typeof obj[key][0] === "object") {
              // Array of objects
              keys.push(`${prefix}${key}:`);
              keys = keys.concat(extractKeys(obj[key][0], "  " + prefix));
            } else {
              // Leaf key
              keys.push(`${prefix}${key}`);
            }
          }
          return keys;
        };
      
        // Generate a prompt for each top-level object in the schema
        const prompts = Object.keys(jsonSchema).map((parentKey) => {
          const keys = extractKeys(jsonSchema[parentKey]);
          return `Given the following JSON data related to the parent object "${parentKey}":\n\n` +
            JSON.stringify(jsonSchema[parentKey], null, 2) +
            `\n\nPlease extract and list all keys within "${parentKey}", including nested keys, in a hierarchical structure.\n\nExample output:\n${keys.join("\n")}`;
        });
      
        return prompts;
    };

    const handleExecute = async () => {
        if (!isFormValid()) return; // Ensure form is valid before proceeding

        setIsProcessing(true); 
        setExecutionStep('Vorbereitung der Daten und Suchparameter...');
        const contextData = {};
        const projectPath = `/${currentTenant}/${selectedProject}`;
        let project_details = {};

        // Include questions and corresponding answers in the search string
        const questionAnswerPairs = Object.entries(questionAnswers).map(
            ([index, answer]) => `${currentProcess.questions[index]}: ${answer}`
        );

        const additionalInfoPairs = formData.additionalInfo.map((info, index) => `Zusätzliche Information ${index + 1}: ${info}`);
        const searchString = [
            currentProcess.name, // Process name
            currentProcess.category, // Process category
            currentProcess.description, // Process description
            ...questionAnswerPairs, // Add question-answer pairs
            ...additionalInfoPairs // Additional info provided by the user
        ].filter(Boolean).join(', '); // Join them as a comma-separated string

        setExecutionStep('Abrufen der Daten aus den gewählten Kontexten...');

        for (const context of activatedContexts) {
            const contextPath = context_dir[context].path;
            if (context === 'strategie' || context === 'protokolle' || context === 'vorgaben') {
                let matches = [];
                try {
                    matches = await getSearchResultsInDocsStatelessV2(
                        config.envs[config.active_env].domain + config.api_path,
                        max_elements,
                        max_depth,
                        contextPath,
                        searchString
                    );
                } catch (error) {
                    console.error(`Failed to fetch matches from ${context_dir[context].label}:`, error);
                }
                const contextString = matches.map(match => 
                    match.text_sections.map(section => section.text).join(', ')
                ).join(', ');
    
                // Only add non-empty strings to contextData
                if (contextString) {
                    contextData[context] = contextString;
                }
            } else if (context === 'berichte') {
                try {
                    //setLoading(true);
                    const result = await fetchReportResults();
                    // Get today's date in YYYY-MM-DD format for comparison
                    const today = new Date().toISOString().split('T')[0];
                    
                    // Filter data by project and exclude reports created today
                    const filteredData = result
                        .filter(item => item.project === selectedProject && item.creation_date !== today)
                        .sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date)); // Sort by creation_date, most recent first
                    
                    // Filter out the last 5 reports (most recent)
                    const lastNReportsFiltered = filteredData.slice(max_elements);
                    const reportsString = lastNReportsFiltered.map(report => {
                        const documentNames = report.document && typeof report.document === 'object'
                            ? Object.values(report.document).map(doc => doc.name).join(', ')
                            : 'Keine Dokumente';
                        return `Dokumente: ${documentNames}, Name: ${report.name}, Erstellungsdatum: ${report.creation_date}, Text: ${report.text}`;
                    }).join('; ');

                    if (reportsString) {
                        contextData[context] = reportsString;
                    }
                } catch (error) {
                    console.error('Failed to fetch report results:', error);
                }
                // Filter reports and gather data
            } else if (context === 'snbs') {
                console.log('SBS context active!')
                const evidence_data = extractAndGroupByIndicatorWithVerbund(selectedIndicators);
                let phase = 'vorpruefung';
                const threshold = 0.5;
                //let matches = await fetchPhaseRequirements(contextPath, phase, evidence_data, threshold); 
                
                const contextString = generateRequirementReport(evidence_data);
                // Only add non-empty strings to contextData
                if (contextString) {
                    contextData[context] = contextString;
                }
            } else if (context === 'projektdetails') {
                try {
                    const projectDetailsArray = await getProject(projectPath);
                    if (projectDetailsArray && projectDetailsArray.length > 0) {
                        const projectDetails = projectDetailsArray[0];
                        const { 
                            start_date = '', 
                            end_date = '', 
                            project_class = 'Unbekannt', 
                            project_category = 'Unbekannt', 
                            predicted_phase = 'Unbekannt', 
                            name = 'Unbekannt' 
                        } = projectDetails;

                        project_details =  {
                            start_date: start_date, 
                            end_date: end_date, 
                            project_class: project_class, 
                            project_category: project_category, 
                            predicted_phase: predicted_phase, 
                            name: name 
                        };

                        const startDateFormatted = start_date ? new Date(start_date).toLocaleDateString() : 'Datum unbekannt';
                        const endDateFormatted = end_date ? new Date(end_date).toLocaleDateString() : 'Datum unbekannt';
                        const projectDetailsString = `Projekt: ${name}, Kategorie: ${project_category}, Klasse: ${project_class}, SOLL Phase: ${getPhaseName(predicted_phase)}, Startdatum: ${startDateFormatted}, Enddatum: ${endDateFormatted}`;

                        if (projectDetailsString) {
                            contextData[context] = projectDetailsString;
                        }
                    } else {
                        console.error('Projekt-Details nicht verfügbar oder das Array ist leer.');
                    }

                } catch (error) {
                    console.error('Failed to fetch project:', error);
                }
            } else if (context === 'aufgaben') {
                try {
                    const tasks = await getExtractedData(projectPath);
                    const tasksString = tasks.map(task => task.description).join(', ');

                    if (tasksString) {
                        contextData[context] = tasksString;
                    }
                } catch (error) {
                    console.error('Failed to fetch tasks:', error);
                }
            } else if (context === 'kbob' && contextData['projektdetails']) {
                const filteredDocuments = filterAndFormatDocuments(selectedPhase, selectedFachbereiche);

                // Format the response content
                const responseContent = 
`
## Projektdokumentation für ${project_details.name}   
**Projektkategorie:** ${project_details.project_category}    
**Projektklasse:** ${project_details.project_class}   
**Erforderliche Dokumente für ${selectedPhase} gemäss KBOB:**   
`;

                // Iterate over each fachbereich group to format documents
                const documentsByFachbereich = Object.keys(filteredDocuments).map(fachbereich => {
                    const documents = filteredDocuments[fachbereich];
                    const formattedDocuments = documents.map((doc, index) => 
`
${index + 1}. **Dokument:** ${doc.document_name}    
**Kategorien**: ${Object.values(doc.categories).filter(category => category.trim() !== "").join(", ") || "Keine Kategorien definiert"}  
**Beschreibung:** ${doc.description}   
**Dokumenttyp:** ${doc.document_types.join(', ')}   
`               ).join('\n');

                    return `### Fachbereich: ${fachbereich}\n${formattedDocuments}`;
                }).join('\n\n');

                // Append the grouped and formatted documents to the base response content
                contextData[context] = `${responseContent}${documentsByFachbereich}`;
            } else if (context === 'uploaded_documents') {
                const flattened_docs = parseResults.map((file) => 'FILE: ' + file.filename + '\nCONTENT: ' + file.text);
                const tenant = currentTenant.trim().replace(/\s/g, '_');
                const prompts = generatePrompts(extractionSchemas['my_hypotheka']);
                //const schema = 'JSON: ' + JSON.stringify(extractionSchemas);
                contextData[context] = prompts + '\n\n' + 'DOCS: ' + flattened_docs[0]/* .join('\n') */;
            }
        }

        if (Object.keys(contextData).length === 0) {
            setModelResponse('Im Projekt wurden keine Daten zur Durchführung des Prozesses gefunden.');
            setIsProcessing(false); // Stop processing
            setExecutionStep(''); // Clear step description
            setCurrentStep(1);
            return;
        }

        setExecutionStep('Generieren der Dokumente...');

        const criteriaDetails = selectedCriteria.map((criteria) => ({
            name: criteria.name,
            description: criteria.description,
            requirements: criteria.return_schema.requirements.map(req => ({
                name: req.name,
                description: req.description
            }))
        }));

        const messages = [
            {
                role: "system",
                content: "You are a helpful assistant skilled at generating structured documents by validating information sources and formatting the data based on user input and context. You output only the results of your work. All generated outputs are in German in swiss writing style."
            },
            {
                role: "user",
                content: `I want to run the ${currentProcess.name} process of type ${currentProcess.category}, and its goal is: ${currentProcess.description}. Generate an output following these instructions: ${currentProcess.prompt}, based on the answers provided by the user: ${questionAnswerPairs}, additional information: ${additionalInfoPairs}, and other resources provided here: ${Object.values(contextData).join(', ')}. Please validate the provided source data, using the following evaluation criterias: ${JSON.stringify(criteriaDetails, null, 2)}. Include a summary of the validated data. Ensure the output adheres to standard conventions and formats for this type of process/document.`
            }
        ];

        if (isDataExtraction) {
            model='mixtral:8x22b';//'mistral-nemo';
            provider='mistral';
            response_format = 'json_object'; // text
        } else {
            model='gpt-4o-mini';
            provider='openai';
            response_format = 'text';
        }

        if (isProjektdokumentation) {
            const response = contextData['kbob'];
            setModelResponse(response); 
        } else if (isSNBS) {
            const response = contextData['snbs'];
            setModelResponse(response);
        } else {
            const queryResponse = await queryGenerativeModel(provider, model, messages, response_format);
            setModelResponse(queryResponse || 'Ergebnisse konnten nicht generiert werden.');     
        }
        
        setIsProcessing(false); // Stop processing
        setExecutionStep(''); // Clear step description
        setCurrentStep(1);
    };

    const reversePhaseMapping = Object.keys(config.assistant.phase_mapping).reduce((acc, key) => {
        const value = getPhaseNumber(key);
        acc[value] = key; // Swap the key and value
        return acc;
    }, {});

    function getPhaseNumber(phaseName) {
        return config.assistant.phase_mapping[phaseName];
    }
    
    function getPhaseName(phaseNumber) {
        return reversePhaseMapping[phaseNumber];
    }
    
    const filterAndFormatDocuments = (phase, fachbereiche) => {
        const phaseNumber = getPhaseNumber(phase);
        let documents = [];
    
        if (phaseNumber === 11 || phaseNumber === 12) {
            documents = documentData
                .filter(doc => doc.sia_phasen.length === 0 && 
                               fachbereiche.some(fach => doc.fachbereiche.includes(fach)))
                .map(doc => formatDocument(doc));
        } else {
            documents = documentData
                .filter(doc => {
                    const matchesPhase = doc.sia_phasen.includes(phase);
                    const matchesFachbereiche = fachbereiche.some(fach => doc.fachbereiche.includes(fach));
                    return matchesPhase && matchesFachbereiche;
                })
                .sort((a, b) => {
                    const phaseNumA = getPhaseNumber(a.phase);
                    const phaseNumB = getPhaseNumber(b.phase);
                    return phaseNumA - phaseNumB;
                })
                .map(doc => formatDocument(doc));
        }
    
        // Group documents by fachbereich
        const groupedByFachbereich = {};
        documents.forEach(doc => {
            doc.fachbereiche.forEach(fach => {
                if (fachbereiche.includes(fach)) {  // Only include relevant fachbereiche
                    if (!groupedByFachbereich[fach]) {
                        groupedByFachbereich[fach] = [];
                    }
                    groupedByFachbereich[fach].push(doc);
                }
            });
        });
    
        return groupedByFachbereich;
    };
       
    const formatDocument = (doc) => ({
        categories: doc.categories,
        document_name: doc.document_name,
        description: doc.description,
        document_types: doc.document_types,
        fachbereiche: doc.fachbereiche
    });

    const renderCurrentScreen = () => {
        if (!currentProcess) return <AssistantMain currentTenant={currentTenant} handleProcessSelect={handleProcessSelect}/>;

        switch (currentStep) {
            case 0:
                return (
                <AssistantConfiguration 
                    context_dir={context_dir} selectedProject={selectedProject} snbs={enrichedCategories}
                    selectedCriteria={selectedCriteria} currentProcess={currentProcess} 
                    questionAnswers={questionAnswers} selectedPhase={selectedPhase} 
                    selectedFachbereiche={selectedFachbereiche} fachbereiche={fachbereiche}
                    phases={phases} formData={formData} folderData={folderData} parseResults={parseResults}
                    acceptanceCriteria={acceptanceCriteria} activatedContexts={activatedContexts} 
                    setSelectedPhase={setSelectedPhase} setSelectedFachbereiche={setSelectedFachbereiche} 
                    setQuestionAnswers={setQuestionAnswers} setActivatedContexts={setActivatedContexts}
                    setSelectedCriteria={setSelectedCriteria} setSelectedProject={setSelectedProject}
                    setFormData={setFormData} setFolderData={setFolderData} setParseResults={setParseResults}
                    selectedIndicators={selectedIndicators} setSelectedIndicators={setSelectedIndicators}
                />);
            case 1:
                return (
                <AssistantOutput 
                    isEditing={isEditing}
                    editableResponse={editableResponse}
                    setEditableResponse={setEditableResponse}
                    modelResponse={modelResponse} 
                />);
            default:
                return (
                <AssistantMain currentTenant={currentTenant} handleProcessSelect={handleProcessSelect}/>
            );
        }
    };

    return (
        <React.Fragment>
            <IconButton 
                color="inherit" 
                onClick={handleOpen}
                className={animationClass} 
                sx={{ width: '56px', // Make sure width and height are the same
                    height: '56px', // Keep height equal to width
                    }}
            >
                <AssistantIcon />
            </IconButton>
    
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'auto' }} // Ensure scrolling for long content
            >
                <Box sx={{ width: '80vw', height: '80vh', bgcolor: 'background.paper', 
                display: 'flex', position: 'relative', flexDirection: 'column', overflow: 'hidden'}}>
                    <AppBar position="static" sx={{
                        background: 'linear-gradient(90deg, rgba(120,10,255,1) 0%, rgba(0,197,212,1) 25%, rgba(21,133,255,1) 75%, rgba(21,79,137,1) 100%)'
                    }}>
                        <Toolbar>
                            <AssistantIcon />
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 2 }}>
                                {`Nukleus Assistent ${currentProcess ? '- ' + currentProcess.name : ''}  ${config.assistant.steps[currentStep] !== undefined ? '- ' + config.assistant.steps[currentStep] : ''}`}
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    {isProcessing && <LinearProgress />} 
    
                    <Box 
                        sx={{ 
                            flex: 1, // Take up available space
                            overflowY: 'auto', 
                            p: 5, 
                            mb:5,
                            pointerEvents: isProcessing ? 'none' : 'auto' 
                        }}
                    >
                        {renderCurrentScreen()}
                    </Box>
    
                    {currentProcess && currentStep >= 0 && (
                        <AssistantNavigation 
                            isProcessing={isProcessing} currentStep={currentStep}
                            handlePrevStep={handlePrevStep} handleExecute={handleExecute}
                            isFormValid={isFormValid}
                            executionStep={executionStep} handleEditToggle={handleEditToggle}
                            isEditing={isEditing} handleClose={handleClose}
                        />
                    )}
                </Box>
            </Modal>
        </React.Fragment>
    );
}

export default Assistant;