import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListSubheader,
  Grid,
  Chip,
  Stack,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const colors = {
  Gesellschaft: '#e0081c',
  Wirtschaft: '#62c4dd',
  Umwelt: '#79b51e',
};

// Utility to lighten color by a percentage
const lightenColor = (color, percentage) => {
  const num = parseInt(color.slice(1), 16),
    amt = Math.round(2.55 * percentage),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

const MultiIndicatorSelector = (
  { data, onSelectionChange, selectedIndicators, setSelectedIndicators }
) => {
  const handleIndicatorToggle = (indicator) => {
    setSelectedIndicators((prev) => {
      const alreadySelected = prev.some((item) => item.id === indicator.id);
      const newSelection = alreadySelected
        ? prev.filter((item) => item.id !== indicator.id) // Remove if already selected
        : [...prev, indicator]; // Add if not selected

      onSelectionChange(newSelection); // Notify parent component of changes
      return newSelection;
    });
  };

  return (
    <Box>
      {/* Display Selected Indicators as Chips */}
      <Stack direction="row" spacing={1} mb={3} flexWrap="wrap">
        {selectedIndicators.map((indicator) => (
  
          <Chip
            key={indicator.id}
            label={indicator.kriteriumIndikatorNr + ' ' + indicator.titel}
            onDelete={() => handleIndicatorToggle(indicator)} // Allow deselection by deleting chip
            color="primary"
            variant="outlined"
          />
        ))}

      </Stack>

      {/* Hierarchical Selector */}
      <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        subheader={<ListSubheader>wählen Sie die Indikatoren in der Hierarchie</ListSubheader>}
      >
        {data.map((topic) => (
          <Accordion key={topic.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container alignItems="center">
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    backgroundColor: colors[topic.titel],
                    borderRadius: '50%',
                    marginRight: 1,
                  }}
                />
                <Typography>{topic.titel}</Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {topic.themen.map((theme) => (
                <Accordion key={theme.id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container alignItems="center">
                      <Box
                        sx={{
                          width: 10,
                          height: 10,
                          backgroundColor: lightenColor(colors[topic.titel], 20),
                          borderRadius: '50%',
                          marginRight: 1,
                        }}
                      />
                      <Typography>{theme.titel}</Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {theme.kriterien.map((kriterium) => (
                      <Accordion key={kriterium.id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Grid container alignItems="center">
                            <Box
                              sx={{
                                width: 8,
                                height: 8,
                                backgroundColor: lightenColor(colors[topic.titel], 40),
                                borderRadius: '50%',
                                marginRight: 1,
                              }}
                            />
                            <Typography>
                              {kriterium.nummer}: {kriterium.titel}
                            </Typography>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <List>
                            {kriterium.indikatoren.map((indicator) => (
                              <ListItem key={indicator.id}>
                                <Grid container alignItems="center">
                                  <Grid item xs={12}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={selectedIndicators.some(
                                            (item) => item.id === indicator.id
                                          )}
                                          onChange={() =>
                                            handleIndicatorToggle(indicator)
                                          }
                                        />
                                      }
                                      label={
                                        <Grid container alignItems="center">
                                          <Box
                                            sx={{
                                              width: 6,
                                              height: 6,
                                              backgroundColor: lightenColor(
                                                colors[topic.titel],
                                                60
                                              ),
                                              borderRadius: '50%',
                                              marginRight: 1,
                                            }}
                                          />
                                          <Typography>{indicator.kriteriumIndikatorNr} {indicator.titel}</Typography>
                                        </Grid>
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </Box>
  );
};

export default MultiIndicatorSelector;
