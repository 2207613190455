import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const generateColors = (numColors) => {
    const colors = [];
    const saturation = 65;
    const lightness = 55;
    
    for (let i = 0; i < numColors; i++) {
        const hue = (i * 360 / numColors) % 360;
        colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }
    
    return colors;
};

const ProjectComparisonDrilldownProjects = ({ criterion, data, onClose }) => {
    if (!data || data.length === 0) {
        return null;
    }

    // Prepare chart data with standardized dates
    const chartData = data.flatMap((projectData) =>
        projectData.reports.map((report) => ({
            project: projectData.project,
            reportDate: new Date(report.reportDate).toISOString().split('T')[0],
            avgScore: report.avgScore,
        }))
    );

    // Filter invalid data points
    const filteredChartData = chartData.filter(
        (item) => item.avgScore !== undefined && !isNaN(item.avgScore)
    );

    // Group data by date with averaging
    const groupedData = filteredChartData.reduce((acc, item) => {
        const date = item.reportDate;
        if (!acc[date]) {
            acc[date] = {
                date,
                sums: {}, // Store sums for each project
                counts: {} // Store counts for each project
            };
        }
        
        // Initialize or add to sum for this project
        acc[date].sums[item.project] = (acc[date].sums[item.project] || 0) + item.avgScore;
        // Initialize or increment count for this project
        acc[date].counts[item.project] = (acc[date].counts[item.project] || 0) + 1;
        
        return acc;
    }, {});

    // Calculate averages from sums and counts
    const averagedData = Object.entries(groupedData).map(([date, data]) => {
        const result = { date };
        
        // Calculate average for each project
        Object.keys(data.sums).forEach(project => {
            result[project] = data.sums[project] / data.counts[project];
        });
        
        return result;
    });

    // Convert to array and sort by date
    const lineChartData = averagedData.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Generate unique colors for each project
    const colors = generateColors(data.length);

    return (
        <Modal open onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: '1200px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '90vh',
                    overflow: 'auto',
                    borderRadius: 1,
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">{`Drilldown: ${criterion}`}</Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <LineChart data={lineChartData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                tick={{ fontSize: 12 }}
                                angle={-45}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {data.map((project, index) => (
                                <Line
                                    key={project.project}
                                    type="monotone"
                                    dataKey={project.project}
                                    stroke={colors[index]}
                                    dot={{ r: 4 }}
                                    connectNulls
                                />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
        </Modal>
    );
};

export default ProjectComparisonDrilldownProjects;