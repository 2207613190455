import React, { useMemo } from "react";
import { Typography, Divider, Grid, Box, Button, Chip } from "@mui/material";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import config from '../config';

function AssistantMain({
    currentTenant,
    handleProcessSelect
}) {
    // Preprocess data to group processes by category
    const groupedProcesses = useMemo(() => {
        const filteredProcesses = config.assistant.processes.filter((process) => {
            if (process.type !== 'Optional') {
                return true; // Keep all non-Optional processes
            }
            // Filter based on the currentTenant
            let optional_modules = [];
            if (config.assistant.modules[currentTenant]) optional_modules = config.assistant.modules[currentTenant];
            return optional_modules.includes(process.name);
        });
        return filteredProcesses.reduce((acc, process) => {
            if (!acc[process.category]) {
                acc[process.category] = [];
            }
            acc[process.category].push(process);
            return acc;
        }, {});
    }, [currentTenant]);

    // Component for process list to avoid repetition
    const ProcessList = ({ processes }) => (
        <Grid container spacing={2} sx={{ p: 3 }}>
            {processes.map(process => (
                <Grid item xs={12} key={process.name}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" sx={{ flex: 1 }}>
                            {process.description}
                        </Typography>
                        <Button
                            variant="outlined"
                            startIcon={<AccountTreeIcon />}
                            onClick={() => handleProcessSelect(process.name)}
                            sx={{ px: 4, py: 1 }}
                        >
                            {process.name}
                        </Button>
                        <Chip label={process.output} sx={{ ml: 2 }} />
                    </Box>
                </Grid>
            ))}
        </Grid>
    );

    // Helper function to format category titles
    const formatCategoryTitle = (category) => {
        // You can add more complex formatting logic here if needed
        return category;
    };

    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'left', ml: 3, mt: 1, mb: 1 }}>
                Wilkommen!
            </Typography>
            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: 'left', ml: 3 }}>
                Mit diesem Assistenten können Sie schnell auf die gängigsten Aufgaben zugreifen und diese auf einfache Weise erledigen.
            </Typography>
            
            {/* Dynamically render categories */}
            {Object.entries(groupedProcesses).map(([category, categoryProcesses], index) => (
                <React.Fragment key={category}>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h6" sx={{ ml: 3, mb:0 }}>
                        {formatCategoryTitle(category)}
                    </Typography>
                    <ProcessList processes={categoryProcesses} />
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

export default AssistantMain;