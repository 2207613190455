import React, { useState, useCallback } from 'react';
import {
  Box, Button, Typography,
  CircularProgress, Alert,
  List, ListItem, ListItemIcon, ListItemText,
  Card, CardContent, CardHeader,
  useTheme, styled
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  FolderOpen as FolderIcon,
  Description as FileIcon,
  Error as ErrorIcon,
  PictureAsPdf as PdfIcon
} from '@mui/icons-material';
import * as pdfjsLib from 'pdfjs-dist';

// Set worker path for pdf.js, deployed locally, loaded dynamically.
pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

// Styled components - Fixed to handle custom props
const DropZone = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDragging' && prop !== 'hasData'
})(({ theme, isDragging, hasData }) => ({
  border: '2px dashed',
  borderColor: isDragging
    ? theme.palette.primary.main
    : hasData
    ? theme.palette.success.main
    : theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: isDragging
    ? alpha(theme.palette.primary.main, 0.05)
    : 'transparent',
  transition: theme.transitions.create(['border-color', 'background-color']),
  cursor: 'pointer'
}));

const FilesList = styled(List)(({ theme }) => ({
  maxHeight: '300px',
  overflow: 'auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2)
}));

const ParseResult = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  maxHeight: '200px',
  overflow: 'auto'
}));

const AssistantUpload = ({
  folderData, setFolderData,
  parseResults, setParseResults,
}) => {
  //const theme = useTheme();
  //const [folderData, setFolderData] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState(null);
  const [parsing, setParsing] = useState(false);
  //const [parseResults, setParseResults] = useState(null);

  const processFolder = async (items) => {
    let totalSize = 0;
    const files = [];
    const pdfFiles = [];

    const processEntry = async (entry) => {
      if (entry.isFile) {
        try {
          const file = await new Promise((resolve) => entry.file(resolve));
          totalSize += file.size;
          files.push({
            name: file.name,
            size: file.size,
            path: entry.fullPath,
            type: file.type
          });
          
          if (file.type === 'application/pdf') {
            pdfFiles.push(file);
            /* console.log('Processed File: ', file) */
          }
        } catch (error) {
          console.error('Error processing file:', error);
        }
      } else if (entry.isDirectory) {
        const dirReader = entry.createReader();
        /* console.log('Processing Folder: ', entry) */
        try {
          const entries = await new Promise((resolve) => {
            dirReader.readEntries(resolve);
          });
          for (const childEntry of entries) {
            await processEntry(childEntry);
          }
        } catch (error) {
          console.error('Error reading directory:', error);
        }
      }
    };

    for (const item of items) {
      const entry = item.webkitGetAsEntry?.() || item.getAsEntry?.();
      if (entry) {
        await processEntry(entry);
      }
    }

    console.log(files)
    return { totalSize, files, pdfFiles };
  };

  // PDF parsing function
  const parsePdfFile = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      const maxPages = pdf.numPages;
      let fullText = '';

      for (let pageNum = 1; pageNum <= maxPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map(item => item.str).join(' ');
        fullText += pageText + '\n\n';
      }

      return {
        filename: file.name,
        pages: maxPages,
        text: fullText
      };
    } catch (error) {
      console.error(`Error parsing PDF ${file.name}:`, error);
      return {
        filename: file.name,
        error: error.message
      };
    }
  };

  // Handle PDF parsing button click
  const handleParsePdfs = async () => {
    if (!folderData?.pdfFiles.length) {
      setError("No PDF files to parse");
      return;
    }

    setParsing(true);
    setError(null);
    
    try {
      const results = await Promise.all(
        folderData.pdfFiles.map(file => parsePdfFile(file))
      );
      setParseResults(results);
    } catch (error) {
      setError("Error parsing PDFs: " + error.message);
    } finally {
      setParsing(false);
    }
  };

  const handleDrop = useCallback(async (e) => {
    e.preventDefault();
    setIsDragging(false);
    setError(null);

    const items = e.dataTransfer.items;
    if (items.length !== 1) {
      setError("Please drop only one folder");
      return;
    }

    const item = items[0];
    const entry = item.webkitGetAsEntry?.() || item.getAsEntry?.();

    if (!entry?.isDirectory) {
      setError("Please drop a folder, not a file");
      return;
    }

    const data = await processFolder([item]);
    setFolderData(data);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const formatSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <Card elevation={2} sx={{  margin: 'auto' }}>
      <CardHeader
        title={
          <Box display="flex" alignItems="center" gap={1}>
            <FolderIcon color="primary" />
            <Typography variant="h6">Quelldaten für die Extraktion hochladen</Typography>
          </Box>
        }
      />
      <CardContent>
        <DropZone
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          isDragging={isDragging}
          hasData={!!folderData}
        >
          {error && (
            <Alert
              severity="error"
              icon={<ErrorIcon />}
              sx={{ mb: 2 }}
            >
              {error}
            </Alert>
          )}

          {!folderData ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <FolderIcon
                sx={{
                  fontSize: 48,
                  color: 'text.secondary',
                  mb: 2
                }}
              />
              <Typography variant="body1" color="text.secondary">
                Ziehen und Ablegen eines Ordners hier
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Nur Mappen werden akzeptiert
              </Typography>
            </Box>
          ) : (
            <Box>
              <Box justifyContent="space-between" justifyItems="left" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Ordnerinhalte:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Gesamtgrösse: {formatSize(folderData.totalSize)}
                </Typography>
              </Box>
              
              
              <Box sx={{ mt: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Box justifyItems="left">
                    <Typography variant="subtitle1">
                      gefundene Dateien: {folderData.files.length}
                    </Typography>
                    <Typography variant="subtitle1">
                      PDF Dateien: {folderData.pdfFiles.length}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleParsePdfs}
                    disabled={!folderData.pdfFiles.length || parsing}
                    startIcon={parsing ? <CircularProgress size={20} color="inherit" /> : <PdfIcon />}
                  >
                    {parsing ? 'Parsen von PDF-Dateien...' : 'PDF Dateien Parsen'}
                  </Button>
                </Box>
                
                <FilesList>
                  {folderData.files.map((file, index) => (
                    <ListItem key={index} divider={index !== folderData.files.length - 1}>
                      <ListItemIcon>
                        {file.type === 'application/pdf' ? 
                          <PdfIcon sx={{p:0, m:0}} color="error" /> : 
                          <FileIcon color="inherit" />
                        }
                      </ListItemIcon>
                      <ListItemText
                        primary={file.name}
                        secondary={
                          <List dense disablePadding>
                            <ListItem key={file.name + file.path + 'path'}>{file.path}</ListItem>
                            <ListItem key={file.name + file.path + 'size'}>{formatSize(file.size)}</ListItem>
                          </List>
                        }
                        primaryTypographyProps={{
                          sx: { textOverflow: 'ellipsis', overflow: 'hidden' }
                        }}
                      />
                    </ListItem>
                  ))}
                </FilesList>

                {parseResults && (
                  <ParseResult>
                    <Typography variant="h6" gutterBottom>
                      PDF Parsing Ergebnisse
                    </Typography>
                    {parseResults.map((result, index) => (
                      <Box key={index} mb={2} sx={{justifyItems:'left'}}>
                        <Typography variant="subtitle2" color="primary">
                          {result.filename}
                        </Typography>
                        {result.error ? (
                          <Typography color="error">
                            Fehler: {result.error}
                          </Typography>
                        ) : (
                          <>
                            <Typography variant="body2">
                              Seiten: {result.pages}
                            </Typography>
                            <Typography variant="body2" sx={{
                              mt: 1,
                              p: 1,
                              backgroundColor: 'grey.100',
                              borderRadius: 1,
                              maxHeight: '100px',
                              overflow: 'auto'
                            }}>
                              {result.text.substring(0, 200)}...
                            </Typography>
                          </>
                        )}
                      </Box>
                    ))}
                  </ParseResult>
                )}

              </Box>
            </Box>
          )}
        </DropZone>
      </CardContent>
    </Card>
  );
};

export default AssistantUpload;