import React from 'react';
import { Tooltip, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import { Modal, Box, Typography, IconButton, Grid, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const prepareChartData = (projectData) => {
    return Object.entries(projectData)
        .filter(([key]) =>
            key !== 'id' &&
            key !== 'project' &&
            typeof projectData[key] === 'number'
        )
        .map(([criterion, value]) => ({
            criterion,
            value: Math.min(Math.max(value * 50, 0), 100), // Normalize: 0–2 → 0–100, clamp between 0–100
            originalValue: value, // Keep the original value for tooltip
        }));
};

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const { criterion, value, originalValue } = payload[0].payload;
        return (
            <Box
                sx={{
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: 4,
                    p: 1,
                }}
            >
                <Typography variant="subtitle2">
                    <strong>{criterion}</strong>
                </Typography>
                <Typography variant="body2">Prozent: {value.toFixed(2)}%</Typography>
                <Typography variant="body2">Durchschnitt: {originalValue.toFixed(2)}</Typography>
            </Box>
        );
    }
    return null;
};

const ProjectComparisonDrilldownCriteria = ({ data, onClose }) => {
    const theme = useTheme();

    if (!data || data.length === 0) {
        return null;
    }

    const chartData = prepareChartData(data);

    return (
        <Modal open onClose={onClose} sx={{ overflowY: 'auto' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: '1200px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '90vh',
                    overflow: 'auto',
                    borderRadius: 1,
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Project Criteria Comparison</Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="subtitle1" align="center" gutterBottom>
                                {data.project}
                            </Typography>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart
                                    data={chartData}
                                    margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="criterion" tick={{ fill: '#666', fontSize: 10 }} />
                                    <YAxis domain={[0, 100]} tick={{ fill: '#666', fontSize: 10 }} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Bar
                                        dataKey="value"
                                        fill={theme.palette.primary.main} // Use primary color from MUI theme
                                        barSize={(100 / chartData.length) * 0.7} // Dynamic bar width
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default ProjectComparisonDrilldownCriteria;