import React from "react";
import { Alert, IconButton, FormControlLabel, FormControl, Grid, Typography, TextField, Button, Select, Checkbox, ListItemText, MenuItem, InputLabel, OutlinedInput } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

function AssistantStandardProcess ({
    currentProcess, questionAnswers, handleAnswerChange, selectedProject, handleProjectSelect,
    formData, handleAdditionalInfoChange, handleRemoveAdditionalInfo, handleAddAdditionalInfo,
    context_dir, activatedContexts, handleContextToggle, userProjects,
    selectedCriteria, handleCriteriaSelect, acceptanceCriteria,
}) {
    //const isProjektdokumentation = currentProcess?.name === "Projektdokumentation";
    //const isSNBS = currentProcess?.name === "Vorprüfung SNBS";
    return (
        <React.Fragment>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>Projektauswahl</Typography>
            <FormControl fullWidth sx={{ }}>
                <InputLabel id="project-select-label">Projekt auswählen</InputLabel>
                <Select
                    labelId="project-select-label"
                    id="project-select"
                    required
                    value={selectedProject}
                    onChange={handleProjectSelect}
                >
                    {userProjects.map((projectItem) => (
                        <MenuItem key={projectItem} value={projectItem.split('/').pop()}>
                            {projectItem.split('/').pop()}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
           
             {/* Zusätzliche Informationen */}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ mt: 3 }}>Zusätzliche Informationen</Typography>
                {/* Safeguard for questions */}
                {currentProcess?.questions?.length ? (
                    currentProcess.questions.map((question, index) => (
                        <TextField
                            fullWidth
                            required
                            label={question}
                            value={questionAnswers[index] || ''} // Bind answer to the state
                            onChange={(e) => handleAnswerChange(index, e.target.value)} // Handle answer change
                        />
                    ))
                ) : (

                    <Typography variant="body2" color="textSecondary">
                        Keine Fragen verfügbar für diesen Prozess.
                    </Typography>
                )}
                </Grid>

                {/* Weitere Infos */}
                {formData.additionalInfo.map((info, index) => (
                    <Grid item xs={12} key={index} display="flex" alignItems="center">
                        <TextField
                            fullWidth
                            required
                            label={`Zusätzliche Information ${index + 1}`}
                            value={info}
                            onChange={(e) => handleAdditionalInfoChange(index, e.target.value)}
                        />
                        <IconButton
                            onClick={() => handleRemoveAdditionalInfo(index)}
                            sx={{ ml: 2 }}
                        >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Button
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleAddAdditionalInfo}
                        variant="outlined"
                    >
                        Weitere Informationen hinzufügen
                    </Button>
                </Grid>
            </Grid>
            
            <React.Fragment>
                {/* Informationsquellen */}
                <Typography variant="subtitle1" sx={{ mt: 3 }}>Informationsquellen</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Daten aus den folgenden vordefinierten Quellen auswählen.
                </Typography>
                <Grid container spacing={2}>
                    {Object.keys(context_dir)
                    .filter((context) => {
                        if (context === 'uploaded_documents' || context === 'kbob') {
                            return false;
                        }
                        return true; // Include all other contexts
                    })
                    .map((context) => (
                        <Grid item key={context}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={activatedContexts.includes(context)} // Pre-select based on activated contexts
                                        onChange={() => {
                                           /*  if (isProjektdokumentation && ['kbob', 'projektdetails'].includes(context)) {
                                                // Prevent toggling for these contexts if conditions are met
                                                return;
                                            } */
                                            handleContextToggle(context); // Update context state
                                        }}
                                        //disabled={isProjektdokumentation && ['kbob', 'projektdetails'].includes(context)}
                                    />
                                }
                                label={context_dir[context].label}
                            />
                        </Grid>
                    ))}
                </Grid>

                {/* Acceptance Criteria */}
                <Typography variant="subtitle1" sx={{ mt: 3 }}>Quellenvalidierung</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Kriterien zur Validierung der Datenquellen auswählen, wird der Antwort automatisch eine Zusammenfassung der bewerteten Informationen hinzugefügt.
                </Typography>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Akzeptanzkriterien</InputLabel>
                    <Select
                        multiple
                        value={selectedCriteria.map((criteria) => criteria.name)} // Map the selectedCriteria to just names for value comparison
                        onChange={handleCriteriaSelect}
                        input={<OutlinedInput label="Akzeptanzkriterien" />}
                        renderValue={(selected) => selected.join(', ')} // Display the selected names as comma-separated values
                    >
                        {acceptanceCriteria.map((criteria) => (
                            <MenuItem key={criteria.name} value={criteria.name}>
                                {/* Check the box if the criteria is already in the selectedCriteria */}
                                <Checkbox checked={selectedCriteria.some((selected) => selected.name === criteria.name)} />
                                <ListItemText primary={criteria.name} secondary={criteria.description} />
                            </MenuItem>
                        ))}
                    </Select>
                    {selectedCriteria.length >= 5 && (
                        <Alert severity="warning">Sie können maximal 5 Kriterien auswählen.</Alert>
                    )}
                </FormControl>

            </React.Fragment>
            
        </React.Fragment>
    );
};

export default AssistantStandardProcess;