import React from "react";
import { TextField } from "@mui/material";
import Markdown from 'markdown-to-jsx';

function AssistantOutput ({
    isEditing, 
    editableResponse,
    setEditableResponse,
    modelResponse
}) {

    return (
        <React.Fragment>
            {isEditing ? (
                // Show editable TextField when in editing mode
                <TextField
                    fullWidth
                    multiline
                    value={editableResponse}
                    onChange={(e) => setEditableResponse(e.target.value)}
                    variant="outlined"
                    sx={{
                        flexGrow: 1, // Allow it to grow to take up available space
                        minHeight: '40vh', 
                        overflowY: 'auto' // Ensure scrollable content if it exceeds height
                    }}
                />
            ) : (
                // Render Markdown when not in editing mode
                <Markdown align='left'>{modelResponse}</Markdown>
            )}
        </React.Fragment>
    );
};

export default AssistantOutput;